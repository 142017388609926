import './css/App.css';
import Gallery from './gallery';
// import './css/other.css';
import './css/star.css';

function App() {
  return (
    <>
      <Gallery />
      
    </>
  );
}

export default App;